import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DialogConfig } from '@shared/ui-components/dialog-container/dialog-config';

@Component({
  selector: 'us2-dialog-container',
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
  ],
  templateUrl: './dialog-container.component.html',
  styleUrl: './dialog-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogContainerComponent {
  @Input() config: DialogConfig = {};
}
