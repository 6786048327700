<div class="dialog-container">
  <button class="close-button" mat-dialog-close aria-label="Close dialog" i18n-aria-label tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>

  <div class="dialog-title flex flex-row justify-center items-center gap-x-4 mb-5">

    @if (config.title) {

      <span>
        {{ config.title }}
      </span>

    }

    <ng-content select="[us2-dialog-title]"></ng-content>

  </div>

  <div class="dialog-content my-5">

    <div class="message text-center">

      @if (config.message) {
        <span [innerHTML]="config.message"></span>
      }

      <ng-content select="[us2-dialog-message]"></ng-content>

    </div>

    <div class="content-container flex flex-col gap-y-6">
      <ng-content></ng-content>
    </div>

  </div>

  <div class="dialog-actions flex flex-row justify-between gap-x-6 mt-6">

    <ng-content select="[us2-dialog-actions]"></ng-content>

  </div>

</div>
